.sidebar-links span {
	font-weight: 700;
	font-size: 1.05em;
}

.sidebar {
	background-color: #252730;
}

.active-link {
	background-color: #191b20;
	color: white;
}

.sidebar-item {
	border-radius: 5px;
	cursor: pointer;
}
