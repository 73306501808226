@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body {
	padding: 0px;
	margin: 0px;
	/* color: white; */
	background-color: #f4f5f7;
	font-family: "Roboto", sans-serif;
}
.insurance-card span {
	font-size: 1.1em;
	font-weight: 500;
}

.bg-light {
	background-color: #fff !important;
}

.border-round {
	border-radius: 15px;
}
tbody {
	background-color: #fff;
}

tbody tr:hover {
	background-color: #f4f5f7;
}

tbody .bg-dark:hover {
	background-color: #1c1f22 !important;
}

.text-offwhite {
	color: rgb(211, 214, 218);
}

table th {
	background-color: #252730;
	color: white;
}

.bg-primary td {
	background-color: blue;
}
.loader {
	font-size: 48px;
	display: inline-block;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: bold;
	color: #263238;
	box-sizing: border-box;
	text-shadow: 0 0 2px #10b068, 0 0 1px #10b068,
		0 0 1px #10b068;
	letter-spacing: 2px;
	position: relative;
}
.loader::after {
	content: "Loading";
	position: absolute;
	left: 0;
	top: 0;
	color: #10b068;
	width: 100%;
	height: 100%;
	overflow: hidden;
	box-sizing: border-box;
	animation: animloader 6s linear infinite;
}

@keyframes animloader {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}
