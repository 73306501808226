.info-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0f3460;
}

.cycle-in-count {
  position: absolute;
  right: 3px;
  font-size: 10em;
  font-weight: 900;
  top: 2%;
  margin-top: 0px;
  /* z-index: -50; */
  color: rgba(69, 88, 88, 0.2);
}

.dealer-card {
  border-radius: 10px;
  background-color: #252730;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 25px;
}

@media screen and (max-width: 640px) {
  .grid-container {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 25px;
  }
}
